<template>
  <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/student-refer') }"
      v-if="hasPermission('campaign_refer')"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Chiến dịch sinh viên</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Thông tin chiến dịch</span>
          </span>
        </li>

        <router-link
            to="/student-refer"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Thông tin chiến dịch</span>
            </a>
          </li>
        </router-link>

            <!-- Chiến dịch sinh viên -->
        <router-link
          to="/campaign/university"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Quản lý danh sách trường</span>
            </a>
          </li>
        </router-link>
        <!-- Báo cáo chiến dịch sinh viên -->
        <router-link
          to="/campaign/report-student"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Danh sách tham gia</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/student-refer/report-seeding"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Seeding</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/student-refer/report-refer"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Sinh viên Refer</span>
            </a>
          </li>
        </router-link>

      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";

export default {
  name: "SidebarComboVoucher",
  mixins: [Common],
  methods:{
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
  }
};
</script>

<style scoped></style>
