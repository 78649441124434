<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/auto-reconcile') }"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-rectangular"></i>
      <span class="menu-text">Đối soát tự động</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Đối soát tự động</span>
          </span>
        </li>

        <router-link
          to="/auto-reconcile/received/aggregate"
          v-slot="{ href, navigate, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Kết quả đối soát</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/auto-reconcile/request/aggregate"
            v-slot="{ href, navigate, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Dữ liệu gửi đối tác</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/auto-reconcile/response/aggregate"
            v-slot="{ href, navigate, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Dữ liệu đối tác phản hồi </span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import permissionsMiddleware from "@/core/mixins/permissionsMiddleware";

export default {
  name: "SidebarAutoReconcile",
  methods: { permissionMiddleware, permissionsMiddleware },
  mixins: [Common],
};
</script>

<style scoped></style>
