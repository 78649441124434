import permissionMiddleware from "./permissionMiddleware";
export default function checkPermissions(permissions)
{
  for (let i = 0; i < permissions.length; i++) {
    if (permissionMiddleware(permissions[i])) {
      return true; // Tồn tại phần tử trùng
    }
  }
  return false; // Không tồn tại phần tử trùng
}
