<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/campaign') }"
    v-if="hasPermission('campaign_student_refer')"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Campaign</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Campaign</span>
          </span>
        </li>

        <router-link
          to="/campaign/mission"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Nhiệm vụ</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/campaign/challenge"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Thử thách</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/campaign/combo"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Combo Voucher</span>
            </a>
          </li>
        </router-link>
         <!-- Config mốc nhận quà  -->
         <router-link
          to="/campaign/mileStone"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Danh sách mốc nhận thưởng</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/campaign/category"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Danh mục</span>
            </a>
          </li>
        </router-link>

        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
                'menu-item-open': hasActiveChildren('/wallet-run/rule'),
              }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon-questions-circular-button"></i>
            <span class="menu-text">Landing Page</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text">Thể lệ</span>
                    </span>
              </li>

              <router-link
                  to="/campaign/rule"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Thể lệ</span>
                  </a>
                </li>
              </router-link>

              <router-link
                  to="/campaign/rule/banner"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Banner</span>
                  </a>
                </li>
              </router-link>
              <router-link
                  to="/campaign/rule/gifts"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Quà tặng</span>
                  </a>
                </li>
              </router-link>
              <router-link
                  to="/campaign/faq"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">FaQ</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>

        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
                'menu-item-open': hasActiveChildren('/wallet-run/report'),
              }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon-questions-circular-button"></i>
            <span class="menu-text">Cài đặt</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text">Cài đặt</span>
                    </span>
              </li>

              <router-link
                  to="/campaign/setting"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">WhiteList Nhiệm vụ</span>
                  </a>
                </li>
              </router-link>

              <router-link
                  to="/campaign/setting/rank-config"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Bảng xếp hạng</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>

        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
                'menu-item-open': hasActiveChildren('/wallet-run/report'),
              }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon-questions-circular-button"></i>
            <span class="menu-text">Thống kê</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text">Thống kê</span>
                    </span>
              </li>

              <router-link
                  to="/campaign/report/challenges"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Thử thách</span>
                  </a>
                </li>
              </router-link>

              <router-link
                  to="/campaign/report/missions"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Nhiệm vụ</span>
                  </a>
                </li>
              </router-link>
              <router-link
                  to="/campaign/report/gifts"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Quà tặng</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>

      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";

export default {
  name: "SidebarCampaign",
  mixins: [Common],
  methods:{
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
  }
};
</script>

<style scoped></style>
