const TYPE_LINK_PAYGATE_TEXT = "Cổng thanh toán";
const TYPE_LINK_NAPAS_CASHIN_TEXT = "Napas Cashin";

export default {
    TYPE_LINK_PAYGATE: "PAYGATE",
    TYPE_LINK_PAYGATE_TEXT: TYPE_LINK_PAYGATE_TEXT,
    TYPE_LINK_DIRECT: "DIRECT_LINK",
    TYPE_LINK_NAPAS: "NAPAS_PAYGATE",
    TYPE_LINK_NAPAS_CASHIN: TYPE_LINK_NAPAS_CASHIN_TEXT,
    DEPOSIT_BY_ATM: 2,
    DEPOSIT_BY_CREDIT_CARD: 3,
    DEPOSIT_BY_BIDV: 4,
    ACTION_TYPE_DEPOSIT: 1,
    ACTION_TYPE_PAYMENT: 4,
    getListBankPaygate() {
        return LIST_BANK_PAYGATE;
    },
    getBankTransfer() {
        return BANK_LIST_TRANSFER;
    },
    getBankLink() {
        return BANK_LIST_WITH_DRAW;
    },
};
const LIST_BANK_PAYGATE = [
    "CTG",
    "VPB",
    "MSB",
    'VCCB',
    'ACB'
];
const BANK_LIST_TRANSFER = [
    "VPBANK",
    "VIETCOMBANK",
    "AGRIBANK",
    "VIETINBANK",
    "TECHCOMBANK",
    "BIDV",
    "SACOMBANK",
    "ABBANK",
    "ACB",
    "BAOVIETBANK",
    "DONGABANK",
    "EXIMBANK",
    "GPBANK",
    "HDBANK",
    "KIENLONGBANK",
    "MSB",
    "NAMABANK",
    "MBBANK",
    "NCB",
    "BAB",
    "OCEANBANK",
    "OCB",
    "LIENVIETPOSTBANK",
    "PVCOMBANK",
    "PUBLICBANK",
    "PGBANK",
    "SCB",
    "SEABANK",
    "SHB",
    "TPBANK",
    "VIB",
    "VIETABANK",
    "VRB",
    "BANVIET",
    "SHINHANBANK",
    "IVB",
    "UOB",
    "WOORIBANK",
    "SAIGONBANK",
];

const BANK_LIST_WITH_DRAW = [
    "VCCB",
    "MBBANK",
    "STBBANK",
    "BIDV",
    "TCB",
    "CTG",
    "MSB",
    "VPB",
    "ACB",
];