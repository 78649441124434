export default {
  ERROR: {
    SERVER: "Lỗi hệ thống. Vui lòng liên hệ với quản trị viên",
    DOWNLOAD_FILE: "Tải xuống file không thành công. Vui lòng thử lại sau",
    UPDATE_TRANSACTION:
      "Cập nhật giao dịch thất bại. Vui lòng liên hệ với quản trị viên",
    UPLOAD_FILE: "Có lỗi trong quá trình upload file. Vui lòng thử  lại sau",
    BU_SOURCE_REQUIRE: "Vui lòng chọn loại BU",
    DATE_REQUIRED: "Vui lòng chọn đúng định dạng ngày",
    DATE_RANGE_INVALID: "Ngày kết thúc phải lớn hơn ngày bắt đầu",
  },

  SUCCESS: {
    RUN_DATA: "Chạy dữ liệu thành công",
    UPDATE_TRANSACTION: "Cập nhật giao dịch thành công"
  }
};
