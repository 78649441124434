<template>
    <div>
        <a
            v-if="order && order.type && order.type === 'BUY_CARD'
            && transaction.origin_status && transaction.origin_status === transactionConfig.STATUS_SUCCESS && !transaction.card_received
            && ['PAYMENT_SUCCESS','SUCCESS','FAIL'].indexOf(order.status) > -1"
            v-on:click="retryOrder()"
            class="btn btn-info font-weight-bold btn-sm float-left"
        >
            Cấp lại thẻ
        </a>
        <a
            v-if="(action && action.type === bankConfig.ACTION_TYPE_PAYMENT && action.payment_type &&
            (action.payment_type === bankConfig.DEPOSIT_BY_ATM || action.payment_type === bankConfig.DEPOSIT_BY_CREDIT_CARD))"
            v-on:click="getPartnerDetail('bank')"
            class="btn btn-info font-weight-bold btn-sm float-left"
        >
            Trạng thái thanh toán
        </a>
        <a
            v-if="(transaction && action && action.type !== bankConfig.ACTION_TYPE_PAYMENT && ((action.payment_type && action.payment_type === bankConfig.DEPOSIT_BY_BIDV) ||
                    (transaction.bank_code && bankConfig.getListBankPaygate().indexOf(transaction.bank_code) > -1)))"
            v-on:click="getPartnerDetail('transaction')"
            class="btn btn-info font-weight-bold btn-sm float-left"
        >
            Trạng thái thanh toán
        </a>
        <a
            v-if="order && order.code"
            v-on:click="openModalChangeOrderStatus()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Đổi trạng thái đơn hàng
        </a>

        <a
            v-if="transaction && action.length > 0 && transaction.action_original === transactionConfig.TRANSACTION_ACTION_WITHDRAW
            && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
            && action[0].status === transactionConfig.STATUS_ACTION_SUCCESS"
            v-on:click="updateTransactionSuccess()"
            class="btn btn-warning font-weight-bold btn-sm float-left"
        >
            Update thành công giao dịch
        </a>

        <a
            v-if="transaction.origin_status === transactionConfig.STATUS_WAIT_TO_REFUND"
            v-on:click="confirmRefundGate()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Xác nhận hoàn tiền
        </a>

        <a
            v-if="transaction && transaction.origin_action === 'DEPOSIT_TRANSFER'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction === null
          && transaction.log_deposit !== null
          && transaction.log_deposit.status == 'CREATED'"
            v-on:click="approveDepositTransfer()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Xác nhận nạp tiền
        </a>
        <a
            v-if="transaction && transaction.origin_action === 'DEPOSIT_TRANSFER'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction === null
          && transaction.log_deposit !== null
          && transaction.log_deposit.status == 'CREATED'"
            v-on:click="rejectDepositTransfer()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Hủy nạp tiền
        </a>
        <a
            v-if="transaction && transaction.origin_action === 'DEPOSIT_TRANSFER'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction !== null
          && transaction.log_deposit !== null
          && transaction.log_deposit.status == 'CREATED'"
            v-on:click="approveDepositTransfer()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Cập nhập trạng thái giao dịch
        </a>
        <a
            v-if="transaction && transaction.origin_action === 'DEPOSIT_TRANSFER'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction !== null
          && transaction.log_deposit !== null
          && transaction.log_deposit.status == 'CREATED'"
            v-on:click="rejectDepositTransfer()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Hủy
        </a>
        <a
            v-if="transaction && transaction.origin_action === 'PAYMENT'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction === null
          && transaction.log_deposit
          && transaction.log_deposit.transfer_content
          && transaction.log_deposit.description
          && transaction.log_deposit.transfer_content !== transaction.log_deposit.description
          && transaction.log_deposit.amount === transaction.log_deposit.transfer_amount
          && transaction.log_deposit.status == 'CREATED'"
            v-on:click="approveDepositTransfer()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Cập nhập trạng thái giao dịch ( thanh toán chưa KYC )
        </a>
        <a
            v-if="transaction && transaction.origin_action === 'PAYMENT'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction === null
          && transaction.log_deposit
          && transaction.log_deposit.transfer_content
          && transaction.log_deposit.description
          && transaction.log_deposit.transfer_content !== transaction.log_deposit.description
          && transaction.log_deposit.amount === transaction.log_deposit.transfer_amount
          && transaction.log_deposit.status == 'CREATED'"
            v-on:click="rejectDepositTransfer()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Hủy ( chưa kyc )
        </a>
        <a

            v-if="(transaction && transaction.transaction_code && ['WBP81B4EKENR'].includes(transaction.transaction_code)) ||(transaction && transaction.origin_action === 'PAYMENT'
          && transaction.action_type === 'DEPOSIT_TRANSFER'
          && transaction.refer_transaction === null
          && transaction.log_deposit
          && ((transaction.log_deposit.amount !== transaction.log_deposit.transfer_amount && transaction.origin_status !== transactionConfig.STATUS_SUCCESS)
              || (transaction.origin_status === transactionConfig.STATUS_SUCCESS && orderStatus && orderStatus !== transactionConfig.STATUS_SUCCESS && orderStatus !== transactionConfig.STATUS_ORDER_PAYMENT_SUCCESS )
              || (transaction.log_deposit.amount === transaction.log_deposit.transfer_amount && orderStatus && orderStatus !== transactionConfig.STATUS_SUCCESS)
            )
          )"
            v-on:click="walletCharge()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Nạp tiền vào ví ( chưa kyc )
        </a>
        <a
            v-if="transaction && transaction.origin_action === 'PAYMENT'
          && transaction.action_type === 'DEPOSIT_TRANSFER'
          && transaction.origin_status !== transactionConfig.STATUS_SUCCESS
          && transaction.refer_transaction
          && transaction.refer_transaction.action === 'DEPOSIT_TRANSFER'"
            v-on:click="navigate(transaction.refer_transaction.code)"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Xem trạng thái bù tiền vào ví
        </a>
        <a
            v-if="transaction.origin_status === transactionConfig.STATUS_WAIT_TO_REFUND"
            v-on:click="cancelRefundGate()"
            class="btn btn-success font-weight-bold btn-sm float-left"
        >
            Hủy yêu cầu
        </a>
        <a
            v-if="orderStatus === transactionConfig.STATUS_ORDER_WAIT_TO_REVIEW"
            v-on:click="addWhiteList()"
            class="btn btn-danger font-weight-bold btn-sm float-left"
        >
            Thêm vào White list
        </a>
        <a
            v-if="orderStatus === transactionConfig.STATUS_ORDER_WAIT_TO_REVIEW"
            v-on:click="doSuccess()"
            class="btn btn-success font-weight-bold btn-sm float-left"
        >
            Hoàn thành giao dịch
        </a>
        <a
            v-if="(orderStatus !== transactionConfig.STATUS_SUCCESS && orderStatus !== transactionConfig.STATUS_ORDER_PAYMENT_SUCCESS) &&
          transaction.origin_status === transactionConfig.STATUS_SUCCESS &&
          typeLink === bankConfig.TYPE_LINK_PAYGATE
      "
            v-on:click="openModalRefundGate()"
            class="btn btn-info font-weight-bold btn-sm float-left"
        >
            Hoàn tiền về cổng
        </a>
        <a
            v-if="
        orderStatus !== transactionConfig.STATUS_SUCCESS &&
        orderStatus !== transactionConfig.STATUS_ORDER_PAYMENT_SUCCESS &&
        transaction.origin_status === transactionConfig.STATUS_ACTION_SUCCESS
      "
            v-on:click="openModalOffset()"
            class="btn btn-warning font-weight-bold btn-sm float-left"
        >
            Bù tiền về ví
        </a>
        <a
            v-if="
        action &&
        action.length > 0 &&
        action[0].status !== undefined &&
        action[0].status === transactionConfig.STATUS_ACTION_SUCCESS
      "
            v-on:click="openModalReverse()"
            class="btn btn-primary font-weight-bold btn-sm float-left"
        >
            Đảo giao dịch
        </a>
        <a v-if="action.length > 0 && (action[0].status === transactionConfig.STATUS_ACTION_FAIL || action[0].status === transactionConfig.STATUS_ACTION_PENDING)
            && (action[0].type === bankConfig.ACTION_TYPE_DEPOSIT || (action[0].type === bankConfig.ACTION_TYPE_PAYMENT && deposit))
            "
            v-on:click="updateActionSuccess()"
            class="btn btn-warning font-weight-bold btn-sm float-left"
        >
            Update thành công Giao dịch
        </a>
        <a v-if="order && order.meta_data && action.length > 0
        && (order.meta_data.merchant_code === 'funtapcashback' || order.meta_data.merchant_code === 'redriver')
        && action[0].type === bankConfig.ACTION_TYPE_PAYMENT"
           v-on:click="orderCashback()"
           class="btn btn-primary font-weight-bold btn-sm float-left"
        >
            Cashback order
        </a>
        <div>
            <b-modal ref="popup-refund-gate" hide-footer>
                <div class="d-block text-center">
                    <h3>Hoàn tiền về cổng</h3>
                </div>
                <div>
                    <b-form-textarea
                        id="textarea"
                        v-model="textRefundGate"
                        placeholder="Nhập lý do hoàn tiền..."
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>

                    <b-button
                        class="mt-3 btn btn-info font-weight-bold btn-sm"
                        block
                        @click="changeStatusWaitToRefund()"
                    >Xác nhận
                    </b-button
                    >
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal ref="change-order-status" hide-footer>
                <div class="d-block text-center">
                    <h3>Đổi trạng thái đơn hàng</h3>
                </div>
                <div>
                    <b-form-select
                        v-model="newOrderStatus"
                        :options="orderStatusList">
                    </b-form-select>
                </div>
                <b-button
                    class="btn btn-warning font-weight-bold"
                    block
                    @click="changeOrderStatus()"
                >Xác nhận
                </b-button>
            </b-modal>
        </div>
        <div>
            <b-modal ref="popup-backToWallet" hide-footer>
                <div class="d-block text-center">
                    <h3>Bù tiền về ví</h3>
                </div>
                <div>
                    <b-form-textarea
                        id="textarea"
                        v-model="text"
                        placeholder="Nhập lý do..."
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>

                    <b-button
                        class="mt-3 btn btn-warning font-weight-bold btn-sm"
                        block
                        @click="offsetWallet()"
                    >Xác nhận
                    </b-button
                    >
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal ref="popup-transDetail" hide-footer>
                <div class="d-block text-center">
                    <h3>Trạng thái giao dịch</h3>
                </div>
                <b-card no-body class="mb-1">
                    <p>
                        Trạng thái : <b>{{ this.inquireInfo.transStatus }}</b>
                    </p>
                    <p>
                        Mã Code : <b>{{ this.inquireInfo.bankStatus }}</b>
                    </p>
                    <p>
                        Mô tả : <b>{{ this.inquireInfo.transDesc }}</b>
                    </p>
                    <div v-if="this.type_link === bankConfig.TYPE_LINK_NAPAS">
                        <p>
                            Mã giao dịch Napas : <b>{{ this.inquireInfo.napasId }}</b>
                        </p>
                        <p>
                            Mã tham chiếu ví - Napas :
                            <b>{{ this.inquireInfo.requestNapasId }}</b>
                        </p>
                        <p>
                            Mã nạp ví : <b>{{ this.inquireInfo.paymentId }}</b>
                        </p>
                    </div>
                    <b-card-header
                        header-tag="header"
                        class="p-1"
                        role="tab"
                        v-if="this.type_link !== bankConfig.TYPE_LINK_NAPAS"
                    >
                        <b-button block href="#" v-b-toggle.collapse-detail variant="info"
                        >Chi tiết
                        </b-button
                        >
                    </b-card-header>
                    <b-collapse id="collapse-detail" role="tabpanel">
                        <b-card-body>
                            <ul id="v-for-object" v-if="this.inquireInfo.detail">
                                <li
                                    v-for="(value, key) in this.inquireInfo.detail"
                                    v-bind:key="key"
                                >
                                    {{ key }} : {{ value }}
                                </li>
                            </ul>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div>
                    <b-button
                        class="mt-2 btn btn-warning font-weight-bold btn-sm"
                        block
                        v-if="
              orderStatus !== transactionConfig.STATUS_WAIT_TO_REVIEW &&
              transStatus !== transactionConfig.STATUS_SUCCESS &&
              transStatus !== transactionConfig.STATUS_REFUNDED
            "
                        @click="updateTransactionStatus"
                    >Cập nhật trạng thái thanh toán
                    </b-button
                    >
                    <b-button
                        class="mt-3 btn btn-danger font-weight-bold btn-sm"
                        block
                        @click="closeModalTransactionDetail"
                    >Huỷ
                    </b-button
                    >
                </div>
            </b-modal>
        </div>
        <div>
            <b-modal ref="popup-transReverse" hide-footer>
                <div class="d-block text-center">
                    <h3>Lý do đảo giao dịch</h3>
                </div>
                <b-card no-body class="mb-3 p-2">
                    <div class="mt-2">
                        <label for="money">Số tiền</label>
                        <b-form-input v-model="amount" placeholder="Nhập số tiền" id="money"></b-form-input>
                    </div>
                    <div class="mt-2">
                        <label for="service">Chọn dịch vụ</label>
                        <multiselect
                            v-model="selected_service"
                            :options="options_service"
                            :multiple="true"
                            label="text"
                            track-by="value"
                            value="value"
                        ></multiselect>
                    </div>
                    <div class="mt-2">
                        <label for="reason">Chọn lý do</label>
                        <multiselect
                            v-model="selected_reason"
                            :options="options_reason"
                            :multiple="true"
                            label="text"
                            track-by="value"
                            value="value"
                        ></multiselect>
                    </div>
                    <div class="mt-2" v-if="show_other_reason">
                        <label for="reason">Lý do</label>
                        <b-form-textarea
                            id="textarea"
                            v-model="reason"
                            placeholder="Nhập lý do..."
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </div>
                </b-card>
                <div>
                    <b-button
                        variant="primary"
                        block
                        @click="submitReverse()"
                    >Xác nhận
                    </b-button
                    >
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Message from "@/core/config/message.config";
import Common from "@/core/mixins/common";
import BankConfig from "@/core/config/bank.config";

const TransactionRepository = RepositoryFactory.get("transactions");
const BankRepository = RepositoryFactory.get("bank");


export default {
    name: "Holding",
    props: ["status", "config", "trans_status", "transaction_id", "type_link", "transaction", "order", "options_service", "reasons", "refers", "action", "wallet_id",'deposit'],
    mixins: [Common],
    data() {
        return {
            orderStatus: undefined,
            transactionConfig: this.config,
            text: "",
            transStatus: undefined,
            textRefundGate: "",
            transactionId: "",
            typeLink: "",
            bankConfig: BankConfig,
            inquireInfo: {
                transStatus: "",
                bankStatus: "",
                transDesc: "",
                failureReason: "",
                detail: null,
            },
            newOrderStatus: "",
            orderStatusList: [
                {value: 'SUCCESS', text: 'Thành công'},
                {value: 'FAIL', text: 'Thất bại'},
            ],
            amount: "",
            selected_service: [],
            selected_reason: [],
            options_reason_default: [
                {value: 'other_reason', text: 'Lý do khác'},
            ],
            options_reason: [],
            reason:"",
            show_other_reason:false
        };
    },
    mounted() {
        this.options_reason = this.options_reason_default;
    },
    watch: {
        amount: function (newVal) {
            if (newVal == '') {
                alert('Vui lòng nhập số tiền.');
                this.amount = "";
            }
        },
        status: function (newVal) {
            this.orderStatus = newVal;

        },
        trans_status: function (newVal) {
            this.transStatus = newVal;
        },
        transaction_id: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.clearState();
            }
            this.transactionId = newVal;
        },
        type_link: function (newVal) {
            this.typeLink = newVal;
        },
        transaction: function (newVal) {
            this.transaction = newVal;
        },
        order: function (newVal) {
            this.order = newVal;
        },
        options_service: function (newVal) {
            this.options_service = newVal;
        },
        selected_service: function (newVal) {
            this.selected_service = newVal;
            this.options_reason = [];
            this.selected_reason = [];
            if(newVal.length > 0) {
                for (var i = 0, length = newVal.length; i < length; i++) {
                    if (newVal[i].value === 'rut_tien') {
                        this.options_reason = [...this.options_reason, ...this.reasons.rut_tien];
                    }
                    if (newVal[i].value === 'the') {
                        this.options_reason = [...this.options_reason, ...this.reasons.the];
                    }
                    if (newVal[i].value === 'veso') {
                        this.options_reason = [...this.options_reason, ...this.reasons.veso];
                    }
                    if (newVal[i].value === 'billing') {
                        this.options_reason = [...this.options_reason, ...this.reasons.billing];
                    }
                    if (newVal[i].value === 'dt_data') {
                        this.options_reason = [...this.options_reason, ...this.reasons.dt_data];
                    }
                    if (newVal[i].value === 'game') {
                        this.options_reason = [...this.options_reason, ...this.reasons.game];
                    }
                    // if (newVal[i].value === 'khac') {
                    //     this.options_reason = [...this.options_reason, ...this.reasons.khac];
                    // }
                }
                this.options_reason = [...this.options_reason, ...this.options_reason_default];
            }else{
                this.options_reason = [...this.options_reason_default];
            }
        },
        selected_reason: function (newVal) {
            this.selected_reason = newVal;
            if(newVal.length === 1 && newVal[0].value === 'other_reason') {
                this.show_other_reason = true;
            }else{
                this.show_other_reason = false;
                this.reason = '';
            }
        },
        reasons: function (newVal) {
            this.reasons = newVal;
        },
        refers: function (newVal) {
            this.refers = newVal;
        },
        action: function (newVal) {
            this.action = newVal;
        },
        wallet_id: function (newVal) {
            this.wallet_id = newVal;
        },
        deposit:function(newVal){
            this.deposit = newVal;
        }
    },
    methods: {
        clearState() {
            this.selected_service = [];
            this.selected_reason = [];
        },
        openModalTransactionDetail() {
            this.$refs["popup-transDetail"].show();
        },
        closeModalTransactionDetail() {
            this.$refs["popup-transDetail"].hide();
        },
        openModalOffset() {
            this.$refs["popup-backToWallet"].show();
        },
        openModalChangeOrderStatus() {
            this.$refs["change-order-status"].show();
        },
        openModalReverse() {
            this.amount = this.reverseMoney(this.transaction.amount ? this.transaction.amount : this.action[0].amount);
            this.$refs["popup-transReverse"].show();
        },

        async getPartnerDetail(type) {
            try {
                this.$bus.$emit("show-loading", true);
                // if (this.type_link === this.bankConfig.TYPE_LINK_NAPAS_CASHIN) {
                // var action = this.action[0];
                if (type === 'bank') {
                    let response = await BankRepository.napasCashinDetail({
                        transaction_id: this.transactionId,
                        action_type: "check",
                    });
                    this.$bus.$emit("show-loading", false);
                    const body = response.data;
                    if (body.error_code === 1) {
                        return this.showError(body.message ? body.message : Message.ERROR.SERVER);
                    }
                    this.inquireInfo.transStatus = body.data.trans_status;
                    this.inquireInfo.transDesc = body.data.message;
                    this.inquireInfo.bankStatus = body.data.code;
                    this.inquireInfo.napasId = body.data.napas_id;
                    this.inquireInfo.requestNapasId = body.data.request_napas_id;
                    this.inquireInfo.paymentId = body.data.payment_wallet_id;
                }else{
                    let response = await TransactionRepository.getPartnerDetail({
                        transaction_id: this.transactionId,
                    });
                    this.$bus.$emit("show-loading", false);
                    const body = response.data;
                    if (body.error_code === 1) {
                        return this.showError(body.message ? body.message : Message.ERROR.SERVER);
                    }
                    this.inquireInfo.transStatus = body.data.trans_status;
                    this.inquireInfo.transDesc = body.data.trans_desc;
                    this.inquireInfo.bankStatus = body.data.status;
                    this.inquireInfo.failureReason = body.data.failure_reason;
                    this.inquireInfo.detail = body.data;
                }

                this.openModalTransactionDetail();
            } catch (e) {
                this.$bus.$emit("show-loading", false);
                return this.showError(Message.ERROR.SERVER);
            }
        },

        updateTransactionStatus: async function () {
            try {
                this.$bus.$emit("show-loading", true);

                if (this.type_link === this.bankConfig.TYPE_LINK_NAPAS) {
                    let response = await BankRepository.napasCashinDetail({
                        transaction_id: this.transactionId,
                        action_type: "sync",
                    });
                    this.$bus.$emit("show-loading", false);
                    const body = response.data;
                    if (body.error_code === 1) {
                        return this.showError(body.message ? body.message : Message.ERROR.UPDATE_TRANSACTION);
                    }
                    this.showSuccess(Message.SUCCESS.UPDATE_TRANSACTION);
                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                } else {
                    let params = {
                        transaction_id: this.transactionId,
                        action: "update",
                        force_update: 1,
                    };
                    let response = await TransactionRepository.getPartnerDetail(params);
                    this.$bus.$emit("show-loading", false);

                    const body = response.data;
                    if (body.error_code == 1) {
                        return this.showError(Message.ERROR.UPDATE_TRANSACTION);
                    }

                    this.showSuccess(Message.SUCCESS.UPDATE_TRANSACTION);

                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                }
            } catch (e) {
                this.$bus.$emit("show-loading", false);
                return this.showError(Message.ERROR.SERVER);
            }
        },
        async offsetWallet() {
            if (confirm("Chắc chắn bù ví chứ?")) {
                this.$bus.$emit("show-loading", true);
                let transaction_id = this.$route.query.transaction_id;
                if (transaction_id === "undefined") {
                    alert("Thiếu thông tin transaction_id");
                    window.history.back();
                }
                let params = {
                    transaction_id: transaction_id,
                    reason: this.text,
                };
                let response = await TransactionRepository.offsetWallet(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
            this.$refs["popup-backToWallet"].hide();
        },
        openModalRefundGate() {
            this.$refs["popup-refund-gate"].show();
        },
        async changeStatusWaitToRefund() {
            if (confirm("Chắc chắn muốn hoàn tiền về cổng chứ?")) {
                this.$bus.$emit("show-loading", true);
                let params = {
                    transaction_id: this.transactionId,
                    reason: this.textRefundGate,
                };
                let response = await TransactionRepository.changeStatusWaitToRefund(
                    params
                );
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
            this.$refs["popup-refund-gate"].hide();
        },
        async doSuccess() {
            if (confirm("Xác nhận hoàn thành giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response = await TransactionRepository.doSuccessPaymentWaitToReview(
                    params
                );
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async addWhiteList() {
            if (confirm("Xác nhận add White List?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response =
                    await TransactionRepository.addWhiteListPaymentInternational(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async confirmRefundGate() {
            if (confirm("Xác nhận yêu cầu hoàn tiền?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response = await TransactionRepository.confirmRefundGate(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async cancelRefundGate() {
            if (confirm("Hủy yêu cầu hoàn tiền?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response = await TransactionRepository.cancelRefundGate(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async approveDepositTransfer() {
            if (confirm("Xác nhận hoàn thành giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response = await TransactionRepository.approveDepositTransfer(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                window.location.reload();
            }
        },
        async rejectDepositTransfer() {
            if (confirm("Xác nhận hủy giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response = await TransactionRepository.rejectDepositTransfer(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                window.location.reload();
            }
        },
        async walletCharge() {
            if (confirm("Xác nhận nạp tiền vào ví user ( chỉ nạp khi user hoàn tất kyc)?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.transactionId};
                let response = await TransactionRepository.walletCharge(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                window.location.reload();
            }
        },
        navigate(transactionCode) {
            let inputURL = window.location.href;
            let url = new URL(inputURL);
            url.searchParams.set('transaction_id', transactionCode);
            let modifiedPathWithQuery = url.pathname + url.search;
            let modifiedURL = inputURL.split('?')[0] + modifiedPathWithQuery;
            window.open(modifiedURL);
        },
        async changeOrderStatus() {
            if (confirm("Xác nhận chuyển trạng thái đơn hàng?")) {
                if (!this.newOrderStatus) {
                    alert('Chưa chọn trạng thái mới');
                    return;
                }
                this.$bus.$emit("show-loading", true);
                let params = {order_code: this.order.code, status: this.newOrderStatus};
                let response = await TransactionRepository.changeOrderStatus(params);
                this.$bus.$emit("show-loading", false);
                this.notifyAlert("warn", response.data.message);
                if (response.data.error_code == 0) {
                    window.location.reload();
                }
            }
        },
        async retryOrder() {
            if (confirm("Xác nhận cấp lại thẻ cho user?")) {
                this.$bus.$emit("show-loading", true);
                let params = {order_id: this.order.id};
                let response = await TransactionRepository.retryOrder(params);
                this.$bus.$emit("show-loading", false);
                this.notifyAlert("warn", response.data.message);
                if (response.data.error_code == 0) {
                    window.location.reload();
                }
            }
        },
        async submitReverse() {
            if (this.amount == '') {
                alert('Vui lòng nhập số tiền.');
                return false;
            }
            ;
            // if (this.selected_service == '') {
            //     alert('Vui lòng chọn dịch vụ.');
            //     return false;
            // };
            if (this.selected_reason == '' && this.reason == '') {
                alert('Vui lòng chọn lý do.');
                return false;
            };
            if (confirm("Xác nhận đảo giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {
                    // transaction_id: this.transactionId,
                    wallet_transaction_id: this.transaction.wallet_transaction_id ? this.transaction.wallet_transaction_id : this.wallet_id,
                    amount: this.amount,
                    reason: this.reason ? JSON.stringify([{'value':this.reason,'text': this.reason}]) : JSON.stringify(this.selected_reason),
                };
                let response = await TransactionRepository.doReverse(params);
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async updateActionSuccess() {
            if (confirm("Xác nhận update thành công giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {action_id: this.action[0].id};
                let response = await TransactionRepository.doUpdateActionSuccess(
                    params
                );
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async updateTransactionSuccess() {
            if (confirm("Xác nhận update thành công giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {code: this.transaction.code};
                let response = await TransactionRepository.doUpdateTransactionSuccess(
                    params
                );
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
        async orderCashback() {
            if (confirm("Xác nhận cashback giao dịch?")) {
                this.$bus.$emit("show-loading", true);
                let params = {transaction_id: this.action[0].id};
                let response = await TransactionRepository.doCashbackOrder(
                    params
                );
                this.$bus.$emit("show-loading", false);
                alert(response.data.message);
                if (response.data.error_code === 0) {
                    window.location.reload();
                }
            }
        },
    },
};
</script>
<style lang="scss">
p {
    padding-top: 10px;
    padding-left: 10px;
}
</style>
