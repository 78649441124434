<template>
  <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/report') }"
      v-if="permissionsMiddleware(['reportFee', 'reportBank', 'reportPayGate', 'reportProvider', 'reportCoupon'])"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Báo cáo</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Báo cáo phí</span>
          </span>
        </li>

        <router-link
            to="/report/fee"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportFee')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo tổng hợp phí</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/report/bank"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportBank')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo chi tiết ngân hàng</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/report/paygate"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportPayGate')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo chi tiết Cổng</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/report/provider"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportProvider')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo chi tiết NCC dịch vụ</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/report/customer"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportCustomer')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo chi tiết phí thu Khách Hàng</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/report/coupon"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportCoupon')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo khuyến mại</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/report/top-10"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportTopTen')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo top 10 giao dịch khách hàng cá nhân</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/report/transaction-situation"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportTransactionSituation')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo tình hình giao dịch</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/report/accounts"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportAccounts')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo số lượng ví</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/report/new-accounts"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-if="permissionMiddleware('reportNewAccounts')"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Báo cáo số lượng ví mới mở</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import permissionsMiddleware from "@/core/mixins/permissionsMiddleware";

export default {
  name: "SidebarComboVoucher",
  methods: { permissionMiddleware, permissionsMiddleware },
  mixins: [Common],
};
</script>

<style scoped></style>
