<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/voucher') }"
    v-if="checkHasPermissions(['distributeHistory'])"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-rectangular"></i>
      <span class="menu-text">Mã giảm giá</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Mã giảm giá</span>
          </span>
        </li>

        <router-link
          to="/voucher/distribute-history"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="hasPermission('distributeHistory')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[isExactActive && 'menu-item-active']"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Lịch sử cấp voucher</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import checkPermissions from "../../../core/mixins/permissionsMiddleware";

export default {
  name: "SidebarVoucher",
  mixins: [Common],
  methods: {
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
    checkHasPermissions(permissions) {
      return checkPermissions(permissions);
    },
  },
};

</script>

<style scoped></style>
