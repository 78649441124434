<template>
  <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/combo-voucher') }"
      v-if="checkHasPermissions(['combo-voucher-script', 'combo_voucher_campaign', 'combo-voucher-whitelist']) "
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Kịch bản phát quà</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Kịch bản phát quà</span>
          </span>
        </li>

        <router-link
            to="/combo-voucher/event"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Chiến dịch</span>
            </a>
          </li>
        </router-link>

        <router-link
            to="/combo-voucher/script"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Kịch bản phát quà</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/combo-voucher/whitelist"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">WhiteList</span>
            </a>
          </li>
        </router-link>

        <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            v-bind:class="{
                'menu-item-open': hasActiveChildren('/combo-voucher/report'),
              }"
        >
          <a href="#" class="menu-link menu-toggle">
            <i class="menu-icon flaticon-questions-circular-button"></i>
            <span class="menu-text">Thống kê</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                    <span class="menu-link">
                      <span class="menu-text">Thống kê</span>
                    </span>
              </li>

              <router-link
                  to="/combo-voucher/report/log-user-gifts"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Lịch sử phát quà</span>
                  </a>
                </li>
              </router-link>

              <router-link
                  to="/combo-voucher/report/event-voucher-releases"
                  v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">Số lượng quà còn lại</span>
                  </a>
                </li>
              </router-link>

            </ul>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import checkPermissions from "../../../core/mixins/permissionsMiddleware";

export default {
  name: "SidebarComboVoucher",
  mixins: [Common],
  methods:{
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
    checkHasPermissions(permissions) {
      return checkPermissions(permissions);
    }
  }
};
</script>

<style scoped></style>
