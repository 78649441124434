<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/charity') }"
    v-if="hasPermission('charity_config')"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Từ thiện</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Từ thiện</span>
          </span>
        </li>

        <router-link
          to="/charity/organization"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Tổ chức</span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/charity/campaign-charities"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Chiến dịch</span>
            </a>
          </li>
        </router-link>
        <router-link
            to="/charity/users"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Danh sách quyên góp</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";

export default {
  name: "SidebarCampaign",
  mixins: [Common],
  methods:{
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
  }
};
</script>

<style scoped></style>
