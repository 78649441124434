<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren('/marketing') }"
    v-if="permissionsMiddleware(['mkt_voucher'])"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon flaticon2-analytics"></i>
      <span class="menu-text">Chỉ số Marketing</span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">Chỉ số Marketing</span>
          </span>
        </li>

        <router-link
          to="/marketing/voucher"
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="hasPermission('mkt_voucher')"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot">
                <span></span>
              </i>
              <span class="menu-text">Thống kê Voucher</span>
            </a>
          </li>
        </router-link>

          <router-link
              to="/marketing/user-transactions"
              v-slot="{ href, navigate, isActive, isExactActive }"
              v-if="hasPermission('mkt_voucher')"
          >
              <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
              >
                  <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                      </i>
                      <span class="menu-text">Thống kê GD user</span>
                  </a>
              </li>
          </router-link>
      </ul>
    </div>
  </li>
</template>

<script>
import Common from "@/core/mixins/common";
import permissionMiddleware from "@/core/mixins/permissionMiddleware";
import permissionsMiddleware from "@/core/mixins/permissionsMiddleware";

export default {
  name: "SidebarMarketing",
  mixins: [Common],
  methods:{
    hasPermission(toName) {
      return permissionMiddleware(toName);
    },
    permissionsMiddleware
  }
};
</script>

<style scoped></style>
