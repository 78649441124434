<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <meta name="google-signin-client_id" content="1051695176445-c9adba6sn1auv1jg824t535drke8m11b.apps.googleusercontent.com">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
            v-bind:status="this.status"
            v-bind:trans_status="this.trans_status"
            v-bind:transaction_id="this.transaction_id"
            v-bind:type_link="this.type_link"
            v-bind:transaction = "this.transaction"
            v-bind:order = "this.order"
            v-bind:options_service = "this.options_service"
            v-bind:reasons = "this.reasons"
            v-bind:action = "this.action"
            v-bind:refers = "this.refers"
            v-bind:wallet_id = "this.wallet_id"
            v-bind:deposit = "this.deposit"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <div id="LoadingPanel" v-show="loadingPanel">
                <div class="text-center">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
              <transition name="fade-in-up">
                <router-view
                  v-on:status="setStatus"
                  v-on:trans_status="setTransStatus"
                  v-on:transaction_id="setTransId"
                  v-on:type_link="setTypeLink"
                  v-on:transaction="setTransaction"
                  v-on:order="setOrder"
                  v-on:deposit="setDeposit"
                  v-on:options_service="setOptions_service"
                  v-on:reasons="setReasons"
                  v-on:refers="setRefers"
                  v-on:action="setAction"
                  v-on:wallet_id="setWallet_id"
                />
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!--    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>-->
    <KTScrollTop></KTScrollTop>
    <notifications group="foo" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
// import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    // KTStickyToolbar,
    KTScrollTop,
    Loader
  },
  data() {
    return {
      loadingPanel: false,
      status: "",
      trans_status: "",
      transaction_id: "",
      type_link: "",
      transaction: null,
      order: null,
      options_service: null,
      reasons: null,
      refers: null,
      action: null,
      wallet_id: "",
        deposit: null,
    };
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    // setTimeout(() => {
    // Remove page loader after some time
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    // }, 2000);

  },
  created() {
    this.$bus.$on("show-loading", this.showLoading);
  },
  beforeDestroy() {
    this.$bus.$off("show-loading", this.showLoading);
  },
  methods: {
    setOptions_service(data) {
      this.options_service = data.param;
    },

    setReasons(data) {
      this.reasons = data.param;
    },
    setAction(data) {
      this.action = data.param;
    },
    setRefers(data) {
      this.refers = data.param;
    },

    setStatus(data) {
      this.status = data.param;
    },
    setTransStatus(data) {
      this.trans_status = data.param;
    },
    setWallet_id(data) {
      this.wallet_id = data.param;
    },
    setTransId(data) {
      this.transaction_id = data.param;
    },
    setTypeLink(data) {
      this.type_link = data.param;
    },
    setTransaction(data) {
      this.transaction = data.param
    },
    setOrder(data) {
      this.order = data.param
    },
    setDeposit(data) {
      this.deposit = data.param
    },
    showLoading(data) {
      if (!data) {
        setTimeout(() => {
          this.loadingPanel = data;
        }, 500);
        return;
      }
      this.loadingPanel = data;
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
